import axios from "axios";
import { CurrentSiteIdGet } from "./userSettings";
import { createGuid } from "../helpers/utils";
import { FixDate } from "../helpers/dateTimeHelper";

export const siteGetForSite = async (siteId) => {
	try {
		const result = await axios({
			method: "get",
			url: "v2/Site",
			params: {
				SiteId: siteId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const siteGet = async () => {
	const siteId = CurrentSiteIdGet();

	return await siteGetForSite(siteId);
};

export const siteAccessCodeGet = async () => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/Site/AccessCode",
			params: {
				SiteId: siteId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const siteAccessCodeSet = async (accessCode, eltekRequiresAccessCode, distributorRequiresAccessCode) => {
	const siteId = CurrentSiteIdGet();

	try {
		await axios({
			method: "post",
			url: "v2/Site/AccessCode",
			data: {
				siteId,
				siteAccessCode: { accessCode, eltekRequiresAccessCode, distributorRequiresAccessCode },
			},
		});
	} catch (err) {
		console.error(err);
	}
};

export const siteNameSet = async (displayName) => {
	const siteId = CurrentSiteIdGet();

	try {
		await axios({
			method: "post",
			url: "v2/Site",
			data: {
				siteId,
				site: { displayName, name: displayName },
				updateType: "DisplayName",
			},
		});
	} catch (err) {
		console.error(err);
	}
};

export const siteDetailsSetForSite = async (siteId, displayName, description, contractStartDate) => {
	try {
		await axios({
			method: "post",
			url: "v2/Site",
			data: {
				siteId,
				site: { displayName, name: displayName, description, contractStartDate },
				updateType: "SiteDetails",
			},
		});
	} catch (err) {
		console.error(err);
	}
};

export const siteDefaultLocaleSet = async (defaultLocale) => {
	const siteId = CurrentSiteIdGet();

	try {
		await axios({
			method: "post",
			url: "v2/Site",
			data: {
				siteId,
				site: { defaultLocale },
				updateType: "DefaultLocale",
			},
		});
	} catch (err) {
		console.error(err);
	}
};

export const siteProductTypeSet = async (siteId, productType) => {
	try {
		await axios({
			method: "post",
			url: "v2/Site",
			data: {
				siteId,
				site: { productType },
				updateType: "ProductType",
			},
		});
	} catch (err) {
		console.error(err);
	}
};

export const siteMaxAgeSet = async (siteId, maxReadingsAgeMonths) => {
	if (maxReadingsAgeMonths === 0) {
		maxReadingsAgeMonths = null;
	}

	try {
		await axios({
			method: "post",
			url: "v2/Site",
			data: {
				siteId,
				site: { maxReadingsAgeMonths },
				updateType: "MaxAge",
			},
		});
	} catch (err) {
		console.error(err);
	}
};

export const siteDateCreatedSet = async (siteId, dateCreated) => {
	try {
		await axios({
			method: "post",
			url: "v2/Site",
			data: {
				siteId,
				site: { dateCreated: FixDate(dateCreated) },
				updateType: "DateCreated",
			},
		});
	} catch (err) {
		console.error(err);
	}
};

export const siteAlarmNotificationsEnabledSet = async (siteId, alarmNotificationsEnabled) => {
	try {
		await axios({
			method: "post",
			url: "v2/Site",
			data: {
				siteId,
				site: { alarmNotificationsEnabled },
				updateType: "AlarmNotificationsEnabled",
			},
		});
	} catch (err) {
		console.error(err);
	}
};

export const siteAlarmRecordingEnabledSet = async (siteId, alarmRecordingEnabled) => {
	try {
		await axios({
			method: "post",
			url: "v2/Site",
			data: {
				siteId,
				site: { alarmRecordingEnabled },
				updateType: "AlarmRecordingEnabled",
			},
		});
	} catch (err) {
		console.error(err);
	}
};
export const siteSmsAlarmNotificationsEnabledSet = async (siteId, smsAlarmNotificationsEnabled) => {
	try {
		await axios({
			method: "post",
			url: "v2/Site",
			data: {
				siteId,
				site: { smsAlarmNotificationsEnabled },
				updateType: "SmsAlarmNotificationsEnabled",
			},
		});
	} catch (err) {
		console.error(err);
	}
};
export const siteAnnualSmsSegmentLimitSet = async (siteId, annualSmsSegmentLimit) => {
	try {
		await axios({
			method: "post",
			url: "v2/Site",
			data: {
				siteId,
				site: { annualSmsSegmentLimit },
				updateType: "AnnualSmsSegmentLimit",
			},
		});
	} catch (err) {
		console.error(err);
	}
};
export const siteDistributorDetailsSet = async (siteId, distributorName, distributorPhone, distributorEmail) => {
	try {
		await axios({
			method: "post",
			url: "v2/Site",
			data: {
				siteId,
				site: { distributorName, distributorPhone, distributorEmail },
				updateType: "DistributorDetails",
			},
		});
	} catch (err) {
		console.error(err);
	}
};

export const logAcknowledge = async (id) => {
	try {
		await axios({
			method: "delete",
			url: "v2/Log",
			data: { id },
		});
	} catch (err) {
		console.error(err);
	}
};

export const logsList = async (all = false) => {
	try {
		const result = await axios({
			method: "get",
			url: "v2/Logs",
			params: { all },
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const siteStats = async (includeFirstReadingDate = false) => {
	try {
		const result = await axios({
			method: "get",
			url: "v2/Site/Stats",
			params: { includeFirstReadingDate },
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const siteReadingsMaxDateTime = async () => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/Site/ReadingMaxDateTime",
			params: { siteId },
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const siteMap = async (level, onlyIfHasZoneGraphic, activeOnly) => {
	const siteId = CurrentSiteIdGet();
	if (!onlyIfHasZoneGraphic) {
		onlyIfHasZoneGraphic = false;
	}

	try {
		const result = await axios({
			method: "get",
			url: "v2/SiteMap",
			params: {
				siteId,
				level,
				onlyIfHasZoneGraphic,
				activeOnly,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const siteMapFlat = async () => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/SiteMap/Flat",
			params: {
				SiteId: siteId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const siteGraphic = async () => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/SiteGraphic",
			params: { siteId },
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const siteGraphicForSite = async (siteId) => {
	try {
		const result = await axios({
			method: "get",
			url: "v2/SiteGraphic",
			params: { siteId },
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const siteGraphicUpload = async (base64String) => {
	const siteId = CurrentSiteIdGet();

	return siteGraphicUploadForSite(siteId, base64String);
};

export const siteGraphicUploadForSite = async (siteId, base64String) => {
	try {
		const result = await axios({
			method: "post",
			url: "v2/SiteGraphic",
			data: { siteId, base64String },
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const apiSiteAdd = async (displayName, productType, alarmNotificationsEnabled, defaultLocale, distributorName, distributorPhone, distributorEmail, description, contractStartDate) => {
	const siteId = createGuid();

	const alarmRecordingEnabled = alarmNotificationsEnabled;

	try {
		await axios({
			method: "post",
			url: "v2/Site",
			data: {
				siteId,
				site: { displayName, name: displayName, productType, alarmRecordingEnabled, alarmNotificationsEnabled, defaultLocale, distributorName, distributorPhone, distributorEmail, description, contractStartDate },
			},
		});

		return true;
	} catch (err) {
		console.error(err);
		return false;
	}
};

export const apiReindex = async (entityType, idList) => {
	const siteId = CurrentSiteIdGet();

	try {
		await axios({
			method: "patch",
			url: "v2/Reindex",
			data: {
				siteId,
				entityType,
				idList,
			},
		});

		return true;
	} catch (err) {
		console.error(err);
	}
};

export const apiSiteSettingsForSiteList = async (siteId) => {
	try {
		const result = await axios({
			method: "get",
			url: "v2/Site/Settings",
			params: { siteId },
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const apiSiteSettingsList = async () => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/Site/Settings",
			params: { siteId },
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const apiSiteSettingsSave = async (siteSettings) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "post",
			url: "v2/Site/Settings",
			data: { siteId, siteSettings },
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const apiSiteFeaturesList = async () => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/Site/Features",
			params: { siteId, includeDisabled: true },
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};
